form.enxContactWrap {
  display: block;
  margin: auto;
  width: 500px;
  max-width: 100%;
}

form.enxContactWrap input.waves-effect.waves-light.btn-small {
  width: 100%;
}

form.enxContactWrap textarea {
  resize: none;
  height: 110px;
}

.g-recaptcha {
  width: 300px;
  margin: 0 auto 1em auto;
}

p.errorMessage {
  color: red;
  font-size: 12px;
  width: 100%;
  text-align: left;
  margin-top: -30px;
}

#hideComponent {
  display: none;
}
