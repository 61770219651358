h1#ComingSoon {
  font-size: 42px;
}

div#innerCS {
  /* background: red; */
  width: 600px;
  margin: auto;
  max-width: 90%;
}

h1#ComingSoon {
  margin: auto;
  padding-top: 70px;
  text-align: center;
}

img#csLake {
  margin-top: 20px;
  width: 100%;
}
