img.clouds {
  position: absolute;
  z-index: -1;
  width: 20%;
  max-width: 200px;
}

img#cloudOne {
  animation-name: cloudOne;
  animation-duration: 60s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes cloudOne {
  /* example is the "animation-name" */
  from {
    margin-left: 0%;
  } /* from what 0% */
  to {
    margin-left: 50%;
  } /* to what 100% */
}

div#homeImgWrapper {
  max-width: 800px;
  margin: auto;
}

img#cloudTwo {
  width: 26%;
  opacity: 0.4;
  margin-top: -10px;
  animation-name: cloudTwo;
  animation-duration: 80s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes cloudTwo {
  /* example is the "animation-name" */
  from {
    margin-left: 30%;
  } /* from what 0% */
  to {
    margin-left: 6%;
  } /* to what 100% */
}

img#cloudThree {
  right: 0px;
  width: 30%;
  opacity: 0.7;
  margin-top: 50px;
  animation-name: cloudThree;
  animation-duration: 90s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes cloudThree {
  /* example is the "animation-name" */
  from {
    margin-right: 40%;
  } /* from what 0% */
  to {
    margin-right: 20%;
  } /* to what 100% */
}
