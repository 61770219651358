#explorePage .topicBackground {
  left: 0px;
  margin-top: -30px;
  margin-left: -30px;
  width: 100vw;
  padding-top: 10vw;
  padding-bottom: 20px;
}

#explorePage .topicBackground:before {
  content: " ";
  display: block;
  position: absolute;
  z-index: -1;
  left: 0;
  top: 50px;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  background-repeat: no-repeat;
  background-size: 100%, cover;
}

#explorePage h1 {
  padding: 0px 10%;
}

#explorePage .pieWrap {
  height: 100vw;
  max-height: 500px;
}

#explorePage .mainBody h1 {
  max-width: none;
}

#explorePage h2#chartTitle {
  margin-top: 50px;
  font-size: 19px;
}
