/* [type="radio"]:not(:checked),
[type="radio"]:checked {
  position: relative;
  opacity: 1;
  pointer-events: fill;
} */

#optionWrap {
  height: 50px;
}

[type="radio"] {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 6px;
  margin-right: 20px;
}
