.HomePageCards {
  margin: auto;
  width: 800px;
  max-width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.HomePageCards .hpc {
  background: yellow;
  width: 300px;
  margin-left: 10px;
  margin-right: 10px;
  height: 416px;
  margin-top: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 16%) 0px 47px 46px -27px,
    rgb(0 0 0 / 6%) 0px 2px 12px 0px;
}

.HomePageCards div#cardImage {
  width: 90%;
  margin: 5% 5%;
  height: 175px;
  background: rgb(223, 223, 223);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.HomePageCards .hpc p {
  max-width: 80%;
  padding-top: 10px;
}

.HomePageCards .wrapCardButton .bigBtn {
  margin-top: 10px;
  width: 80%;
}

.HomePageCards #cardsDescription {
  font-size: 16px;
  text-align: center;
  margin: auto;
  padding-top: 25px;
  max-width: 800px;
  line-height: 2rem;
}
