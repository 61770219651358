#newsPage {min-height: 100vw;}

div#inputWrap {
    position: absolute;
    left:0px;
    right:0px;
    margin:0 auto;
    margin-top:20px;
    width:80%;
    max-width:800px;
    /* background:red; */
}

input#newsInput {
    width:100%;
    border: 1px solid #dfe1e5;
    box-shadow: none;
    border-radius: 24px;
    text-indent: 20px;
}

input#newsInput:focus {
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
}


button.btn-search {
    position:absolute;
    right:0px;
    top:1px;
    width:60px;
    border-radius: 24px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color:white;
    box-shadow:-1px 0px 0px #ccc;
    height:3em;
    border:none;
}


.contentWrap {
    /* background:red; */
    position:relative;
    margin:auto;
    margin-top:100px;
    width:100%;
    max-width:800px;
}



.articleCard {
    border: 1px solid #dfe1e5;
    box-shadow: none;
    border-radius: 24px;
    padding:0px 30px 10px;
    margin-bottom:30px;
    color:rgb(76, 76, 76);
}

h3#title {font-size:20px;}

p#url {
  font-size:10px;text-overflow: ellipsis;
  overflow: hidden; 
  position:relative;
  text-align:left;
  top:-40px;
  white-space: nowrap;
}

p#description {
    position:relative;
    top:-60px;
    left:0px;
    text-align:left
}

.articleCard  img {
    margin-top:-50px;
    width:100%;
    border-radius:24px
}